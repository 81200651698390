import { useNavigate } from 'react-router-dom';
import {
  useAuthenticationContext,
  ActionTypes,
} from 'context/AuthenticationContext';

import { Logout } from 'services';

export let handleLogout = () => {};

const Unauthorized = () => {
  const navigate = useNavigate();
  const { dispatch } = useAuthenticationContext();

  const handleSystemLogout = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await Logout({
        api_key: token ?? '',
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleLogout = async () => {
    await handleSystemLogout();
    dispatch({
      type: ActionTypes.SET_LOGOUT,
    });
    navigate('/login');
  };

  return null;
};

export default Unauthorized;
