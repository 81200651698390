import { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  useAuthenticationContext,
  ActionTypes,
} from 'context/AuthenticationContext';
import { Logout } from 'services';

import LoadingScreenFallback from 'components/LoadingScreenFallback';
import AppBar from 'components/AppBar';
import Unauthorized from 'components/Unauthorized';

import Login from 'pages/Login';
const Transactions = lazy(() => import('pages/Transactions'));
const Reports = lazy(() => import('pages/Reports'));

function Router() {
  const { dispatch } = useAuthenticationContext();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const role = localStorage.getItem('role');
    if (token && username && role) {
      dispatch({
        type: ActionTypes.SET_USER_DETAIL,
        payload: {
          username: username,
          token: token,
          role: role,
        },
      });
    }
  }, []);

  return (
    <Suspense fallback={<LoadingScreenFallback />}>
      <Routes>
        <Route index path="/login" element={<Login />} />
        <Route element={<AppBar />}>
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/reports" element={<Reports />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
      <Unauthorized />
    </Suspense>
  );
}

export default Router;
