import React, { createContext, useContext, useReducer, Dispatch } from 'react';

interface UserDetail {
  username: string;
  token: string;
  role: string;
}

export type defaultState = {
  authenticated: boolean;
  userDetail: UserDetail | null;
};

export const initialState: defaultState = {
  authenticated: false,
  userDetail: null,
};

export enum ActionTypes {
  SET_USER_DETAIL = 'SET_USER_DETAIL',
  SET_LOGOUT = 'SET_LOGOUT',
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type AuthenticationPayload = {
  [ActionTypes.SET_USER_DETAIL]: defaultState['userDetail'];
  [ActionTypes.SET_LOGOUT]: undefined;
};

export type NavigationActions =
  ActionMap<AuthenticationPayload>[keyof ActionMap<AuthenticationPayload>];

const mainReducer = (
  state: defaultState,
  action: NavigationActions
): defaultState => {
  switch (action.type) {
    case ActionTypes.SET_USER_DETAIL:
      if (action.payload) {
        localStorage.setItem('username', action.payload.username);
        localStorage.setItem('role', action.payload.role);
        localStorage.setItem('token', action.payload.token);
      }
      return { ...state, userDetail: action.payload };
    case ActionTypes.SET_LOGOUT:
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      return { ...state, userDetail: null };
    default:
      return state;
  }
};

const AuthenticationContext = createContext<{
  state: defaultState;
  dispatch: Dispatch<NavigationActions>;
}>({ state: initialState, dispatch: () => null });

type Props = {
  children?: React.ReactNode;
};

export const AuthenticationProvider = ({ children }: Props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AuthenticationContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = () => useContext(AuthenticationContext);
