import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Input, Button, InputProps } from 'antd';
import {
  useAuthenticationContext,
  ActionTypes,
} from 'context/AuthenticationContext';

import { Login } from 'services';

import BoxPattern from 'assets/BoxPattern.svg';
import { ReactComponent as TSBLogo } from 'assets/Logo.svg';

const Container = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr 2fr;
`;

const LogoContainer = styled.div`
  background-image: url(${BoxPattern}),
    linear-gradient(192.39deg, #2b395f 0%, #258d9e 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -4px 0px 34px rgba(0, 0, 0, 0.4);
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 25rem;
`;

const Text = styled.div`
  font-weight: 800;
  font-size: 1.5rem;
`;

const CustomInput = styled(Input)`
  &.ant-input {
    height: 2.5rem;
  }
`;

const CustomButton = styled(Button)`
  &.ant-btn {
    background-color: var(--clr-primary);
    color: white;
    border-color: var(--clr-primary);
    height: 2.5rem;
  }
`;

const ErrorText = styled.div`
  color: red;
`;

const LoginPage = () => {
  const { dispatch } = useAuthenticationContext();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const handleUsernameChange: InputProps['onChange'] = (e) => {
    const value = e.target.value;
    setUsername(value);
    setUserNameError(false);
    setLoginFailed(false);
  };

  const handlePasswordChange: InputProps['onChange'] = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError(false);
    setLoginFailed(false);
  };

  const validateLogin = () => {
    if (username === '') {
      setUserNameError(true);
    }
    if (password === '') {
      setPasswordError(true);
    }
    if (username !== '' && password !== '') {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    try {
      const { data } = await Login({
        user: username,
        password,
      });
      const { api_key, role } = data;
      dispatch({
        type: ActionTypes.SET_USER_DETAIL,
        payload: {
          username,
          token: api_key,
          role: role,
        },
      });
      if (role === 'op') {
        navigate('/transactions');
      } else if (role === 'acc') {
        navigate('/reports');
      }
    } catch (error) {
      console.error(error);
      setLoginFailed(true);
    }
  };

  return (
    <Container>
      <LogoContainer>
        <TSBLogo />
      </LogoContainer>
      <LoginContainer>
        <InputContainer>
          <Text>Welcome back, Please Login</Text>
          <CustomInput
            value={username}
            placeholder="Username"
            onChange={handleUsernameChange}
            status={usernameError ? 'error' : ''}
          ></CustomInput>
          <CustomInput
            value={password}
            placeholder="Password"
            type="password"
            onChange={handlePasswordChange}
            status={passwordError ? 'error' : ''}
          ></CustomInput>
          {loginFailed && (
            <ErrorText>
              Username or Password is invalid, Please try again!
            </ErrorText>
          )}

          <CustomButton onClick={validateLogin}>Login</CustomButton>
        </InputContainer>
      </LoginContainer>
    </Container>
  );
};

export default LoginPage;
