import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
        :root {
        --clr-primary: #2B395F;
        --clr-placeholder-text:#6D6D6D;
      }
      html {
        height: 100%;
        box-sizing: border-box;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      body {
        margin: 0;
        padding: 0;
        font-family: IBM Plex Sans Thai, sans-serif;
        min-height: 100%;
      }

      input, button, select, optgroup, textarea {
        font-family: IBM Plex Sans Thai, sans-serif;
      }
`;

export default GlobalStyle;
