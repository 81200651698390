import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

import { APP_BAR_HEIGHT } from './AppBar';

import { ReactComponent as Logo } from 'assets/AppBarLogo.svg';

const Container = styled.div`
  height: ${APP_BAR_HEIGHT};
  background-color: var(--clr-primary);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
`;

const LoadingContainer = styled.div`
  margin: auto;
  height: calc(100vh - 64px);
  display: grid;
  place-content: center;
`;

const LoadingScreenFallback = () => {
  return (
    <>
      <Container>
        <Logo />
      </Container>
      <LoadingContainer>
        <Spin size="large" tip="Loading..." />
      </LoadingContainer>
    </>
  );
};

export default LoadingScreenFallback;
