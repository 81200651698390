import axios, { AxiosResponse, AxiosError } from 'axios';
import * as requestBodyType from 'services/requestBodyTypes';
import * as responseBodyType from 'services/responseBodyType';

import { handleLogout } from 'components/Unauthorized';

export { requestBodyType };
export { responseBodyType };

const PREFIX = '/api/v1';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL + PREFIX
  : PREFIX;

export const get = async <R,>(url: string): Promise<AxiosResponse<R, any>> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.get<R>(url);
    return response;
  } catch (error) {
    const err = error as AxiosError<any>;
    if (err.response?.data?.code === 5) {
      await handleLogout();
    }
    throw error;
  }
};

export const post = async <R,>(
  url: string,
  body: any,
  config?: any,
  controller?: AbortController
): Promise<AxiosResponse<R, any>> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.post<R>(url, body, {
      signal: controller?.signal,
      ...config,
    });
    return response;
  } catch (error) {
    const err = error as AxiosError<any>;
    if (err.response?.data?.code === 5) {
      await handleLogout();
    }
    throw error;
  }
};

export const Login = (
  body: requestBodyType.Login,
  controller?: AbortController
) => {
  return post<responseBodyType.loginResponse>('/login', body, controller);
};

export const Logout = (
  body: requestBodyType.Logout,
  controller?: AbortController
) => {
  return post<any>('/logout', body, controller);
};

export const getTransactionList = (
  body: requestBodyType.getTopUpTransactionListForAdmin,
  controller?: AbortController
) => {
  return post<responseBodyType.getTopUpTransactionListForAdminResponse>(
    '/get-topup-transaction-list-for-admin',
    body,
    controller
  );
};

export const getTopupTransactionCSV = (
  body: requestBodyType.getTopUpTransactionCSV,
  controller?: AbortController
) => {
  return post<any>(
    '/get-topup-transaction-csv',
    body,
    { responseType: 'blob' },
    controller
  );
};
